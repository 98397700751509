import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ccd1210"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onSubmit"]
const _hoisted_2 = { class: "input-row items" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "input-wr" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { class: "input-wr" }
const _hoisted_7 = { class: "items-list" }
const _hoisted_8 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: "default-form",
    onSubmit: _withModifiers($setup.onAddItem, ["prevent"])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($setup.props.itemName), 1),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          class: "input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.item) = $event)),
          type: "text",
          placeholder: `Add ${$setup.props.itemName}`
        }, null, 8, _hoisted_5), [
          [_vModelText, $setup.item]
        ]),
        _createElementVNode("button", {
          class: "button primary",
          type: "button",
          onClick: $setup.onAddItem
        }, "Add")
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.itemList, (item) => {
            return (_openBlock(), _createElementBlock("li", null, [
              _createTextVNode(_toDisplayString(item.text), 1),
              _createElementVNode("img", {
                class: "remove",
                src: require(`@/assets/icons/trash.svg`),
                onClick: ($event: any) => ($setup.onRemoveItem(item.id))
              }, null, 8, _hoisted_8)
            ]))
          }), 256))
        ])
      ])
    ])
  ], 40, _hoisted_1))
}